<template>
	<v-dialog persistent max-width="1200">
		<VeeForm ref="vForm" :validation-schema="schema" @submit="saveTicket">
			<v-card>
				<DialogHeader
					title="Create a Ticket"
					@close-dialog="$emit('update:modelValue', false)"
				>
				</DialogHeader>
				<v-card-text class="pt-4 overflow-auto">
					<v-row>
						<v-col>
							<v-alert border="start" type="info">
								<span v-if="$userHasPriv('hasInternalAccess')">
									<p>
										A <strong>Ticket</strong> is a means of reporting an issue,
										either internal to Ventus and/or Customer impacting in any
										way. A ticket can be associated to an individual device, a
										customer/host as a whole, or an internal component within
										Ventus that is being affected.
									</p>
									<br />
									<p>
										Upon creating a ticket, you will automatically become the
										owner of the ticket and responsible for tracking its
										progress to resolution. Enter information in the required
										fields and click
										<strong>Create Ticket</strong> when you are done.
									</p>
								</span>
								<span v-else>
									<p>
										A ticket is a means of reporting an issue to the Ventus NOC
										(Network Operations Center). Fill out the following form in
										order to have your inquiry or problem reviewed by a Ventus
										NOC technician.
									</p>
								</span>
							</v-alert>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="4">
							<VTextFieldWithValidation
								v-model="ticketFormObject.title"
								name="title"
								label="Title"
							/>
						</v-col>
						<v-col cols="12" md="4">
							<VSelectWithValidation
								v-model="ticketFormObject.selectedType"
								name="selectedType"
								label="Type"
								:items="ticketTypeOptions"
								item-title="desc"
								item-value="code"
								clearable
								@update:model-value="
									$refs.selectedSubType.resetField();
									ticketSubTypeOptions = GetTicketSubTypes($event);
								"
							/>
						</v-col>
						<v-col cols="12" md="4">
							<VSelectWithValidation
								v-show="hasSubTypes"
								ref="selectedSubType"
								v-model="ticketFormObject.selectedSubType"
								name="selectedSubType"
								label="Sub-Type"
								:items="ticketSubTypeOptions"
								item-title="desc"
								item-value="code"
								clearable
								hint="Select a ticket type to see a list of options"
								persistent-hint
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col v-show="canHaveCarrierTicket" cols="12" md="3">
							<VTextFieldWithValidation
								v-model="ticketFormObject.carrierTicket"
								name="carrierTicket"
								label="Carrier Ticket #"
							/>
						</v-col>
						<v-col cols="12" md="3">
							<VTextFieldWithValidation
								v-model="ticketFormObject.carrierAssetId"
								name="carrierAssetId"
								label="Carrier Asset ID"
							/>
						</v-col>
						<v-col cols="12" md="3">
							<VTextFieldWithValidation
								v-model="ticketFormObject.customerCaseId"
								name="customerCaseId"
								label="Customer Case ID"
							/>
						</v-col>
						<v-col cols="12" md="3">
							<VSelectWithValidation
								v-model="ticketFormObject.selectedHostLocations"
								name="selectedHostLocations"
								label="Host Locations"
								:items="hostLocationsOptions"
								multiple
								chips
								closable-chips
								clearable
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="4">
							<GlobalSearchInput
								v-model="ticketFormObject.usersToNotify"
								:search-types="['user']"
								label="Users to Notify"
								placeholder="Search for users..."
								multiple
								chips
								closable-chips
								clearable
							></GlobalSearchInput>
						</v-col>
						<v-col v-if="canAssignTicket" cols="6" md="4">
							<VCheckboxWithValidation
								v-model="ticketFormObject.assignSelf"
								label="Assign to Self?"
							></VCheckboxWithValidation>
						</v-col>
						<v-col v-if="!ticketFormObject.assignSelf" cols="12" md="4">
							<GlobalSearchInput
								v-model="ticketFormObject.userToAssign"
								name="userToAssign"
								:search-types="['user']"
								label="Assignee"
								placeholder="Search for user..."
								clearable
							></GlobalSearchInput>
						</v-col>
					</v-row>
					<v-row v-if="associationOptions.length">
						<v-col cols="12" md="4">
							<VSelectWithValidation
								v-model="ticketFormObject.selectedAssociation"
								name="selectedAssociation"
								label="Association Type"
								:items="associationOptions"
								item-title="label"
								item-value="val"
								clearable
								hint="Select a type and sub-type to see a list of options"
								persistent-hint
							/>
						</v-col>
						<v-col cols="12" md="8" align-self="center">
							<v-chip
								v-if="
									ticketFormObject.selectedAssociation === 'customer' &&
									$userHasPriv('hasCustomerAccess')
								"
								label
								size="x-large"
								color="primary"
							>
								{{ userCustomerName }}
							</v-chip>
							<GlobalSearchInput
								v-if="
									ticketFormObject.selectedAssociation === 'customer' &&
									$userHasPriv('hasInternalAccess')
								"
								v-model="ticketFormObject.customerAssociation"
								name="customerAssociation"
								:search-types="['customer']"
								label="Customer to Associate"
								placeholder="Search for a customer..."
								clearable
							/>
							<GlobalSearchInput
								v-if="ticketFormObject.selectedAssociation === 'unit'"
								v-model="ticketFormObject.unitAssociation"
								name="unitAssociation"
								:search-types="['unit']"
								:customer-id-filter="userCustomerId"
								label="Units to Associate"
								placeholder="Search for units..."
								multiple
								chips
								closable-chips
								clearable
							/>
							<GlobalSearchInput
								v-if="ticketFormObject.selectedAssociation === 'network_object'"
								v-model="ticketFormObject.networkObjectAssociaton"
								name="networkObjectAssociaton"
								:search-types="['network_object']"
								:customer-id-filter="userCustomerId"
								label="Network Objects to Associate"
								placeholder="Search for network objects..."
								multiple
								chips
								closable-chips
								clearable
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="6">
							<VSelectWithValidation
								v-model="ticketFormObject.selectedPrimaryStatus"
								name="selectedPrimaryStatus"
								label="Primary Status"
								:items="primaryStatusOptions"
								item-title="desc"
								item-value="code"
								clearable
								@update:model-value="
									$refs.selectedSecondaryStatus.resetField();
									secondaryStatusOptions = GetSecondaryStatuses($event, true);
								"
							/>
						</v-col>
						<v-col cols="12" md="6">
							<VSelectWithValidation
								ref="selectedSecondaryStatus"
								v-model="ticketFormObject.selectedSecondaryStatus"
								name="selectedSecondaryStatus"
								label="Secondary Status"
								:items="secondaryStatusOptions"
								item-title="desc"
								item-value="code"
								clearable
								hint="Select a primary status to see a list of options"
								persistent-hint
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<VSelectWithValidation
								v-model="ticketFormObject.selectedOnSiteSupport"
								name="selectedOnSiteSupport"
								label="On-Site Support"
								:items="onSiteSupportOptions"
								multiple
								chips
								closable-chips
								clearable
							/>
						</v-col>
					</v-row>
					<v-row v-if="$userHasPriv('hasInternalAccess')">
						<v-col>
							<VSelectWithValidation
								v-model="ticketFormObject.escalationLevel"
								name="escalationLevel"
								label="Escalation Level"
								lookup="ticket_escalation_level"
								item-title="label"
								item-value="id"
								clearable
							/>
						</v-col>
					</v-row>
					<v-row v-if="$userHasPriv('hasInternalAccess')">
						<v-col cols="12" md="3">
							<VCheckboxWithValidation
								v-model="ticketFormObject.descriptionIsPublic"
								label="Description Is Public?"
								hide-details
							></VCheckboxWithValidation>
						</v-col>
						<v-col
							v-if="ticketFormObject.descriptionIsPublic"
							align-self="center"
							cols="12"
							md="9"
						>
							<v-alert type="warning" density="compact">
								By selecting this option, you are making this note available to
								everyone.
							</v-alert>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col>
							<VTextAreaWithValidation
								v-model="ticketFormObject.ticketDescription"
								name="ticketDescription"
								label="Ticket Description"
								height="150"
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn block :loading="loading" type="submit">Submit</v-btn>
				</v-card-actions>
			</v-card>
		</VeeForm>
	</v-dialog>
</template>

<script>
import { object, string, number, array } from "yup";
import { Form as VeeForm } from "vee-validate";
import { useUserStore } from "@/stores/store.user";
import useTicket from "@/composables/TicketComposable.js";

export default {
	components: {
		VeeForm,
	},
	props: {
		prefilledUnits: {
			type: Array,
			default: () => [],
		},
		prefilledNetworkObjects: {
			type: Array,
			default: () => [],
		},
	},
	emits: ["update:modelValue"],
	setup() {
		const {
			HostLocationOptions,
			OnSiteSupportOptions,
			GetTicketTicketTypes,
			GetTicketSubTypes,
			GetPrimaryStatuses,
			GetSecondaryStatuses,
			SubmitTicket,
			ticketFormObject,
			ticketPayload,
			associationOptions,
			canAssignTicket,
			canHaveCarrierTicket,
		} = useTicket();

		return {
			HostLocationOptions,
			OnSiteSupportOptions,
			GetTicketTicketTypes,
			GetTicketSubTypes,
			GetPrimaryStatuses,
			GetSecondaryStatuses,
			SubmitTicket,
			ticketFormObject,
			ticketPayload,
			associationOptions,
			canAssignTicket,
			canHaveCarrierTicket,
		};
	},
	data() {
		return {
			loading: false,
			ticketTypeOptions: this.GetTicketTicketTypes(),
			ticketSubTypeOptions: [],
			hostLocationsOptions: this.HostLocationOptions,
			primaryStatusOptions: this.GetPrimaryStatuses(true),
			secondaryStatusOptions: [],
			onSiteSupportOptions: this.OnSiteSupportOptions,
		};
	},
	computed: {
		...mapState(useUserStore, {
			userCustomerId: "customer_id",
			userCustomerName: "customer_name",
		}),
		hasSubTypes() {
			if ($userHasPriv("hasCustomerAccess")) {
				this.ticketFormObject.selectedType === 2;
			}

			return this.ticketSubTypeOptions.length > 0;
		},
		schema() {
			let temp = {};
			temp.title = string().required();
			temp.selectedType = number().required();

			if (this.hasSubTypes) {
				temp.selectedSubType = number().required();
			}

			if (this.canAssignTicket && !this.ticketFormObject.assignSelf) {
				temp.userToAssign = string().required();
			}

			if (this.associationOptions.length) {
				temp.selectedAssociation = string().required();

				if (this.ticketFormObject.selectedAssociation === "customer") {
					temp.customerAssociation = number().required();
				} else if (this.ticketFormObject.selectedAssociation === "unit") {
					temp.unitAssociation = array().min(1);
				} else if (
					this.ticketFormObject.selectedAssociation === "network_object"
				) {
					temp.networkObjectAssociaton = array().min(1);
				}
			}

			temp.selectedPrimaryStatus = number().required();
			temp.selectedSecondaryStatus = number().required();
			temp.ticketDescription = string().required();

			return object(temp);
		},
	},
	created() {
		if (this.$route.name === "units-hostname-tickets") {
			this.ticketFormObject.selectedType = 1;
			this.ticketFormObject.unitAssociation.push(this.$route.params.hostname);
			this.ticketFormObject.selectedAssociation = "unit";
		}

		if (this.prefilledUnits.length) {
			this.ticketFormObject.selectedType = 1;
			this.ticketFormObject.unitAssociation = this.prefilledUnits;
			this.ticketFormObject.selectedAssociation = "unit";
		}
	},
	methods: {
		saveTicket() {
			this.loading = true;

			return this.SubmitTicket(this.ticketPayload)
				.then((response) => {
					this.$router.push({
						name: "tickets-id",
						params: { ticketId: response.idTicket },
					});
					this.$emit("update:modelValue", false);
				})
				.finally(() => (this.loading = false));
		},
	},
};
</script>
