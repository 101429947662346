<template>
	<v-card-item :class="background ? background : backgroundColor">
		<template #prepend>
			<slot name="icon"></slot>
		</template>
		<v-card-title :class="titleColor ? titleColor : textColor">
			<template v-if="title">{{ title }}</template>
			<slot v-else name="title"></slot>
		</v-card-title>
		<v-card-subtitle :class="titleColor ? titleColor : textColor">
			<template v-if="subtitle">{{ subtitle }}</template>
			<slot v-else name="subtitle"></slot>
		</v-card-subtitle>
		<template #append>
			<slot name="actions"></slot>
			<v-btn
				v-if="!hideCloseButton"
				:class="titleColor ? titleColor : textColor"
				icon="fa-solid fa-times"
				variant="text"
				@click="$emit('closeDialog')"
			></v-btn>
		</template>
	</v-card-item>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "",
		},
		subtitle: {
			type: String,
			default: "",
		},
		color: {
			type: String,
			default: "primary",
		},
		background: {
			type: String,
			default: "",
		},
		titleColor: {
			type: String,
			default: "",
		},
		hideCloseButton: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["closeDialog"],
	data() {
		return {};
	},
	computed: {
		backgroundColor() {
			return `bg-${this.color}-darken-4`;
		},
		textColor() {
			return `text-${this.color}-lighten-1`;
		},
	},
};
</script>

<style lang="scss">
.v-card-item .v-card-subtitle {
	padding: 0;
}
.v-dialog > .v-overlay__content > .v-card > .v-card-item {
	padding: 0.625rem 1rem;
}
.v-dialog .v-card-item + .v-card-text {
	padding-top: 0.5rem;
}
</style>
